import valFallback from "../logos/validator_fallback.svg";

export const thumbnails: { [identity: string]: string } = {
  E5F274B870BDA01D:
    "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg",
  AE4C403A6E7AA1AC:
    "https://s3.amazonaws.com/keybase_processed_uploads/909034c1d36c1d1f3e9191f668007805_360_360.jpeg",
  "00B79D689B7DC1CE":
    "https://s3.amazonaws.com/keybase_processed_uploads/3a844f583b686ec5285403694b738a05_360_360.jpg",
  "975D494265B1AC25":
    "https://s3.amazonaws.com/keybase_processed_uploads/e7eb89ea1c41eb10fbaa66a6e5b60b05_360_360.jpg",
  "3A7D5C9B0B88BEA1":
    "https://s3.amazonaws.com/keybase_processed_uploads/92cd665c2158b7380de6ed499d95ba05_360_360.jpg",
  "48608633F99D1B60":
    "https://s3.amazonaws.com/keybase_processed_uploads/cd2a41a8df34c773128fa394f2acda05_360_360.jpg",
  "045D374A62F15B56":
    "https://s3.amazonaws.com/keybase_processed_uploads/7b1f41f3d8c9fc1f6f5094da6dc25f05_360_360.jpg",
  "3804A3D13B6CB379":
    "https://s3.amazonaws.com/keybase_processed_uploads/b92befb344f4a12a7d75b00cf7d09905_360_360.jpg",
  CA9AC67C3BF42517:
    "https://s3.amazonaws.com/keybase_processed_uploads/5b3f8006bfc195759c7ba64651ce2705_360_360.jpg",
  "90B597A673FC950E":
    "https://s3.amazonaws.com/keybase_processed_uploads/e1378cd4d5203ded716906687ad53905_360_360.jpg",
  "7C88A757E65A5445":
    "https://s3.amazonaws.com/keybase_processed_uploads/3289a1771f20b736abb38f15653f7d05_360_360.jpg",
  EBB03EB4BB4CFCA7:
    "https://s3.amazonaws.com/keybase_processed_uploads/2826e38259411adafb416505fb948c05_360_360.jpg",
  "7BDD4C2E94392626":
    "https://s3.amazonaws.com/keybase_processed_uploads/82c0a77a4fa7eadcc7c19db426535e05_360_360.jpg",
  "0878BA6BE556C132":
    "https://s3.amazonaws.com/keybase_processed_uploads/1855362ac6629cbc7158012eb363e405_360_360.jpg",
  C47845226662AF47:
    "https://s3.amazonaws.com/keybase_processed_uploads/41a14755f6dcc57604ebfe75e3e4ed05_360_360.jpg",
  "3EB2AEED134D7138":
    "https://s3.amazonaws.com/keybase_processed_uploads/e5adbbd46f16053bc6b5adce029e9005_360_360.jpg",
  D0D8B80F1C5C70B5:
    "https://s3.amazonaws.com/keybase_processed_uploads/90c0c3d64fccc193617615091cff3b05_360_360.jpg",
  "209E3458ADC6CAF5":
    "https://s3.amazonaws.com/keybase_processed_uploads/5ab389f2634e08eab59d0829a3c9a405_360_360.jpg",
  "0BC47B3228CBF46C":
    "https://s3.amazonaws.com/keybase_processed_uploads/85f8e8fa2c6a700d21e35711d2c71105_360_360.jpg",
  D16E26E5C8154E17:
    "https://s3.amazonaws.com/keybase_processed_uploads/d8d03bfdc35339c30a502b73b9ab6d05_360_360.jpg",
  "06E24C7678282B53":
    "https://s3.amazonaws.com/keybase_processed_uploads/b19f88c597a7bb169d486e9e03ff7f05_360_360.jpg",
  "3E38E52A12F94561":
    "https://s3.amazonaws.com/keybase_processed_uploads/0c58779792b331d87c7a7ecbfd7ef805_360_360.jpg",
  "28DC4101DA38C22C":
    "https://s3.amazonaws.com/keybase_processed_uploads/4cb8ffca762335930b55f99df0608b05_360_360.jpg",
  "535BF8D68742ACED":
    "https://s3.amazonaws.com/keybase_processed_uploads/67de65c69c21ff8f4bdd59cbae42a705_360_360.jpg",
  "67A577430DBBCEE0":
    "https://s3.amazonaws.com/keybase_processed_uploads/c96cf0ee2dc8f102b6c3eaf1fbdf4c05_360_360.jpg",
  "6408AA029ADBE364":
    "https://s3.amazonaws.com/keybase_processed_uploads/3a6087c347a6ee0d02f82b4fe2081f05_360_360.jpg",
  "26FE476C84A3C760": valFallback,
  CC434B6FE536F51B:
    "https://s3.amazonaws.com/keybase_processed_uploads/8349f3ab6852a8419c1987ad9096c605_360_360.jpg",
  "8957C5091FBF4192":
    "https://s3.amazonaws.com/keybase_processed_uploads/3d5a3bd02e0c30db7949a371bbc4d705_360_360.jpg",
  "2861F5EE06627224":
    "https://s3.amazonaws.com/keybase_processed_uploads/f5b0771af36b2e3d6a196a29751e1f05_360_360.jpeg",
  "268AE2FE71E9E7C4":
    "https://s3.amazonaws.com/keybase_processed_uploads/846a159d07bf007bcca2c35416d46705_360_360.jpg",
  DCB176E79AE7D51F:
    "https://s3.amazonaws.com/keybase_processed_uploads/5dacfc1ad84eecdb4a01fd893d479805_360_360.jpeg",
  "8A9FC930E1A980D6":
    "https://s3.amazonaws.com/keybase_processed_uploads/a36cb442d15672ecd180b29c698a2505_360_360.jpg",
  "436039F82528A43A":
    "https://s3.amazonaws.com/keybase_processed_uploads/6cfad5c62a803390a8fc93fdfc9c2605_360_360.jpg",
  F12B081334CBE0C6:
    "https://s3.amazonaws.com/keybase_processed_uploads/63585765d299338807f158d6aadd2e05_360_360.jpg",
  DE810BE47E3B73B3:
    "https://s3.amazonaws.com/keybase_processed_uploads/aa8593e2c57af8cc2725d9270f5ea905_360_360.jpg",
  CD80FCB702D70807:
    "https://s3.amazonaws.com/keybase_processed_uploads/26fb1a95bd986007a0de27d718218f05_360_360.jpg",
  A57DAB9B09C7215D:
    "https://s3.amazonaws.com/keybase_processed_uploads/6ab87291cc2506fb6f1ef6a657e62005_360_360.jpg",
  CF0852DD298E2B0D:
    "https://s3.amazonaws.com/keybase_processed_uploads/964085f0b9d19d53554ec37bddda9005_360_360.jpg",
  "5736C325251A8046":
    "https://s3.amazonaws.com/keybase_processed_uploads/39002ba27b7a8267410a2fedd5528305_360_360.jpg",
  "4801FCDCD4F93E9B":
    "https://s3.amazonaws.com/keybase_processed_uploads/e2158c45b766c4204ec1c90977cb0105_360_360.jpg",
  "47434737FEC2418A":
    "https://s3.amazonaws.com/keybase_processed_uploads/5c3d7bac0929f250929ac178a8d19405_360_360.jpg",
  "5992A6D423A406D6":
    "https://s3.amazonaws.com/keybase_processed_uploads/9d337c16fa39ef101c37131dbec2cf05_360_360.jpg",
  E2A5772A111FD119:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1q4mlwtw3ncve72na65xacck557wcknc8j0lmke.png",
  "8E92184569CD8E2D":
    "https://s3.amazonaws.com/keybase_processed_uploads/eaaad4b2c4e30990a58320de74695d05_360_360.jpg",
  "0A6AF02D1557E5B4":
    "https://s3.amazonaws.com/keybase_processed_uploads/d56ce0bdda17f73d4aa895d1626e2505_360_360.jpg",
  "9AE70F9E3EDA8956":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1h2c47vd943scjlfum6yc5frvu2l279lwjep5d6.png",
  "5F1D6AC7EA588676":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1u6jr0pztvsjpvx77rfzmtw49xwzu9kas05lk04.png",
  "6386E8C1B6217AC2":
    "https://s3.amazonaws.com/keybase_processed_uploads/1dbbcb3590eb95b16521c13a42921605_360_360.jpg",
  "35892AC552B8A0A8":
    "https://s3.amazonaws.com/keybase_processed_uploads/0295f24a5694e2af5e51cb391705ed05_360_360.jpg",
  "165F85FC0194320D":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1y0us8xvsvfvqkk9c6nt5cfyu5au5tww24nrlnx.png",
  "3999DA33020A4DBC":
    "https://s3.amazonaws.com/keybase_processed_uploads/c1bfe4c1d4f6c8f8d66baa152d50e805_360_360.jpg",
  "6783E9F948541962":
    "https://s3.amazonaws.com/keybase_processed_uploads/51d3573458db9c383c8a2f730af84105_360_360.jpg",
  FA260EE7A0113432:
    "https://s3.amazonaws.com/keybase_processed_uploads/af106005e2439232a6f19bcb75c52605_360_360.jpg",
  AD3CDBC91802F94A:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1kgddca7qj96z0qcxr2c45z73cfl0c75pf37k8l.png",
  F87ADDB700C0CC94:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1glmy88g0uf6vmw29pyxu3yq0pxpjqtzqr5e57n.png",
  "1ECD13F96C55C0CD":
    "https://s3.amazonaws.com/keybase_processed_uploads/a779c9e7185eb63ccbaec307c9cf1f05_360_360.jpg",
  "679281030DD1209A":
    "https://s3.amazonaws.com/keybase_processed_uploads/edaa56528c25783aac7044e54bf7d805_360_360.jpg",
  D75509198CE782A6:
    "https://s3.amazonaws.com/keybase_processed_uploads/c5b1dfddf88bb3a757a8a9c66651e105_360_360.jpg",
  "29A97D4100A83471":
    "https://s3.amazonaws.com/keybase_processed_uploads/ed2db5629853790d5b26ea12fb576405_360_360.jpg",
  "8687EB49D3AC9208":
    "https://s3.amazonaws.com/keybase_processed_uploads/3c47b62f3d28ecfd821536f69be82905_360_360.jpg",
  ADBDB0178E4441BE:
    "https://s3.amazonaws.com/keybase_processed_uploads/331ef65a8c486e2eb1f1846986509d05_360_360.jpg",
  DD06F013A474ACA3:
    "https://s3.amazonaws.com/keybase_processed_uploads/2d924fd3a739f81b89e55343c40f9b05_360_360.jpg",
  "1C32EF4035953E8B":
    "https://s3.amazonaws.com/keybase_processed_uploads/a9c3a52a07f0c9d7ec2ef0714eda1305_360_360.jpg",
  DA08751C2062AB36:
    "https://s3.amazonaws.com/keybase_processed_uploads/aa00b591b6e2d9386932c24e89662a05_360_360.jpg",
  C7449E61C271EAA8:
    "https://s3.amazonaws.com/keybase_processed_uploads/b30ff45dd938ae77c602393299869605_360_360.jpg",
  C92C6965D89F07A3: valFallback,
  "4062E136FF6C8968":
    "https://s3.amazonaws.com/keybase_processed_uploads/1b8734001b87673cb18080a048a90105_360_360.jpg",
  "0B5217ACAE18F4C9":
    "https://s3.amazonaws.com/keybase_processed_uploads/d56a8afc0d99e63498f82e7b89d89b05_360_360.jpg",
  "103DCE407C9F1D13":
    "https://s3.amazonaws.com/keybase_processed_uploads/836ea91be7df31a3a865e43541e97c05_360_360.jpg",
  D372724899D1EDC8:
    "https://s3.amazonaws.com/keybase_processed_uploads/298543a07bc5ab8a0a72405ecb6bfc05_360_360.jpg",
  "9C7571030BEF5157":
    "https://s3.amazonaws.com/keybase_processed_uploads/fd488355ab385fc78fa6c0cee76c3205_360_360.jpg",
  "2ABCBF8F9F31AF0E":
    "https://s3.amazonaws.com/keybase_processed_uploads/89c86f490e46970b9ffdcf10ab120505_360_360.jpg",
  "51468B615127273A":
    "https://s3.amazonaws.com/keybase_processed_uploads/4c37bcb94523674a84d57cab554c5c05_360_360.jpg",
  "4D3303E20A4D2C32":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2F051cdbd6d051d3cafcdb45377c6b5c05_360_360.jpg&w=128&q=75",
  "913CE38447233C01":
    "https://s3.amazonaws.com/keybase_processed_uploads/61d84fdd3a5969a1f8d832ca8f536a05_360_360.jpg",
  "5A8AB49CF5CAAF3C":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper16s96n9k9zztdgjy8q4qcxp4hn7ww98qk5wjn0s.png",
  "609F83752053AD57":
    "https://s3.amazonaws.com/keybase_processed_uploads/3074ffa8484e5d89597d53356bf9d905_360_360.jpg",
  FD3702E722561505:
    "https://s3.amazonaws.com/keybase_processed_uploads/3e76421e42ca09d2d4e9a5c2bdee5905_360_360.jpg",
  CC806AFFDB2EE85A:
    "https://s3.amazonaws.com/keybase_processed_uploads/a4e90eb76d45735341e07f5314766105_360_360.jpg",
  "216E0EE1BA80B5F8":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper14amduhjazqhwtkhm6kutdcy4ux5zazf5k803tq.png",
  "87D9921253A2A9EB":
    "https://www.a41.io/_next/image?url=https%3A%2F%2Fa41-web-static.s3.ap-northeast-2.amazonaws.com%2Fpublic%2Fimgs%2Fa41_logo_steel.png&w=1920&q=75",
  FEF740F1760E1B56:
    "https://s3.amazonaws.com/keybase_processed_uploads/085d09c506dceeb931a504f249736705_360_360.jpg",
  "9203983F91296B66":
    "https://s3.amazonaws.com/keybase_processed_uploads/1c4591dc0094cd6711bc1f5d18d9a405_360_360.jpg",
  A2EA7DE76AD57E1A:
    "https://s3.amazonaws.com/keybase_processed_uploads/fee9b2d6de139c06ca94d6c59f055c05_360_360.jpg",
  C46C8329BB5F48D8:
    "https://s3.amazonaws.com/keybase_processed_uploads/0df6d91e7e57b42a7e17b2cc62541905_360_360.jpg",
  "9A516A1CD4116BBF":
    "https://s3.amazonaws.com/keybase_processed_uploads/3fcc4caebb2c8e949829a7b090be3805_360_360.jpg",
  "7F82E4F0CAA26298":
    "https://s3.amazonaws.com/keybase_processed_uploads/26b948e192f3c31a9032c4e1ef89a405_360_360.jpg",
  "5CCA4F526B9F85DA":
    "https://s3.amazonaws.com/keybase_processed_uploads/a8b06c066e7fb928abef3e4508adf205_360_360.jpg",
  "06B033BAC39DA21C":
    "https://s3.amazonaws.com/keybase_processed_uploads/6dc14362803c12b4f06ebc58e9958705_360_360.jpg",
  "94FEC9A766EF8D04":
    "https://s3.amazonaws.com/keybase_processed_uploads/a740e37f4adeebc0706acd71f5a41205_360_360.jpg",
  A2879F08F59FB0AF:
    "https://s3.amazonaws.com/keybase_processed_uploads/5575ed509a4d4d395987000cf36a5b05_360_360.jpg",
  "63575EE3F0F9FAFC":
    "https://s3.amazonaws.com/keybase_processed_uploads/f41a124017811be653748cece3560205_360_360.jpg",
  "55387C0472199D52":
    "https://s3.amazonaws.com/keybase_processed_uploads/96a587e698a795f91ba12618ab6f0105_360_360.jpg",
  "55A5F88B4ED52D3E":
    "https://s3.amazonaws.com/keybase_processed_uploads/4c88923608ce72dd4d6ebb8b93e5d005_360_360.jpg",
  C5C28A947096C28A:
    "https://s3.amazonaws.com/keybase_processed_uploads/245cc914fddb8f5957e635cff782ab05_360_360.jpg",
  B4750D7ECB3F0409:
    "https://s3.amazonaws.com/keybase_processed_uploads/53059456b623614fa46be05b2cde5405_360_360.jpg",
  "55B2C411EE64C03A":
    "https://s3.amazonaws.com/keybase_processed_uploads/b3a42d54dc983ddf8fc7a63e2df1bd05_360_360.jpg",
  B41FCF161C4B971B:
    "https://s3.amazonaws.com/keybase_processed_uploads/9788639bbc2f4d70a3bf1ce557913005_360_360.jpg",
  DA413860B22A8E07:
    "https://s3.amazonaws.com/keybase_processed_uploads/fbacc8ad6054ffb6a0ae559c81c25205_360_360.jpg",
  F2E67996F3D5EB16:
    "https://s3.amazonaws.com/keybase_processed_uploads/474e46094121ca6b1a76168b3efe7e05_360_360.jpg",
  "909A480D5643CCC5":
    "https://s3.amazonaws.com/keybase_processed_uploads/3b72c1a2f1efd5f7fed0bacd6a787e05_360_360.jpg",
  "11A2797A6DD3873D":
    "https://s3.amazonaws.com/keybase_processed_uploads/e1f0a5408b34916e30fab52f5455d605_360_360.jpg",
  F10E3CDCBC4EA7AA:
    "https://s3.amazonaws.com/keybase_processed_uploads/7422803e583735db284898cafd8e8605_360_360.jpg",
  E73AFD8985423B14:
    "https://s3.amazonaws.com/keybase_processed_uploads/128efcca8fffd52fed0cae7aa9f25d05_360_360.jpg",
  D41C757E7F05563FC04351B41E09665F32FE7217: valFallback,
  "6696D60A73064DFE":
    "https://s3.amazonaws.com/keybase_processed_uploads/1c6f791ce6df2b7b14ea1d0447ab1c05_360_360.jpg",
  "125E6FE219457130":
    "https://s3.amazonaws.com/keybase_processed_uploads/967fccb20f83489ea9ae1b803e235d05_360_360.jpg",
  "805F39B20E881861":
    "https://s3.amazonaws.com/keybase_processed_uploads/d48739023a250815c4ac564c9870ec05_360_360.jpg",
  F422F328C14AFBFA:
    "https://s3.amazonaws.com/keybase_processed_uploads/6f8216af8c5805a9bde9098316e5e405_360_360.jpg",
  "2E3A8285E6B547B2":
    "https://s3.amazonaws.com/keybase_processed_uploads/3476fe9f5565f97c1169474fd0b6f505_360_360.jpg",
  "536585A71903C50F":
    "https://s3.amazonaws.com/keybase_processed_uploads/3f9db55d73c7509bc05181b655997405_360_360.jpg",
  "44937E3DA9AA699A": valFallback,
  "70C162B0473634FD":
    "https://s3.amazonaws.com/keybase_processed_uploads/7457583aa6c1316335719ec6cd1ba905_360_360.jpg",
  "1326A75B9148A214":
    "https://s3.amazonaws.com/keybase_processed_uploads/d635534def81aba879cb5379edbe0f05_360_360.jpg",
  "8D6FC89A2E9A7FC7":
    "https://s3.amazonaws.com/keybase_processed_uploads/cf5c4280c5934629bd598e736ad4c705_360_360.jpg",
  D540D0234B3AE1B8:
    "https://s3.amazonaws.com/keybase_processed_uploads/63706c19c6fcf72a5314acd2ed335505_360_360.jpg",
  "0CE19EE3E4BA48E5":
    "https://s3.amazonaws.com/keybase_processed_uploads/9ad31731b3dfa00c77508b5427ee9005_360_360.jpg",
  "1441B829AD71D8F4":
    "https://s3.amazonaws.com/keybase_processed_uploads/cd7521be90327c83415410782295c105_360_360.jpg",
  DF1CDD6E03CCF372:
    "https://s3.amazonaws.com/keybase_processed_uploads/8888513d9793e14936de315195d18d05_360_360.jpg",
  E308F774D80FF40B:
    "https://s3.amazonaws.com/keybase_processed_uploads/1be50ea4c1a26ff72b068a3ab0590205_360_360.jpg",
  "8BD21C9C536D6CBF":
    "https://s3.amazonaws.com/keybase_processed_uploads/672aefe29d7601557a8a4e27680d6d05_360_360.jpg",
  "146F545F37D34202":
    "https://s3.amazonaws.com/keybase_processed_uploads/09fb0ccfd7c3819ea1ecfd7ead33df05_360_360.jpg",
  A0C8B5A2BDDCA82B:
    "https://s3.amazonaws.com/keybase_processed_uploads/946d1d67195714b80ea47077c8927f05_360_360.jpg",
  "5F406D7064437F89":
    "https://s3.amazonaws.com/keybase_processed_uploads/1ad8339052870fb172a22c566d03f005_360_360.jpg",
  "86A0F841E24F3C34":
    "https://s3.amazonaws.com/keybase_processed_uploads/592dc594eb5c43f5e53c63fcaf765405_360_360.jpg",
  "5505D3E11EF42968":
    "https://s3.amazonaws.com/keybase_processed_uploads/2668127cdab5c1c73acd1a6a7f907705_360_360.jpg",
  DEF3590B1DCD96A4:
    "https://s3.amazonaws.com/keybase_processed_uploads/22719e70a0bf62d953ee027e7595d005_360_360.jpg",
  FD161E9548A427C9:
    "https://s3.amazonaws.com/keybase_processed_uploads/94036970019a31e86d83f8781e283205_360_360.jpg",
  "2CC4D67B2136C051":
    "https://s3.amazonaws.com/keybase_processed_uploads/81013bebd3eb4c403a55488012c67e05_360_360.jpg",
  "3F5BD795E6AB49AC":
    "https://s3.amazonaws.com/keybase_processed_uploads/fefadd02959e67386708ed6e0baf5005_360_360.jpg",
  "1510797E867F484E":
    "https://s3.amazonaws.com/keybase_processed_uploads/6ebcaa02df0e4c457c7aa5dc4b54bc05_360_360.jpg",
  "833F4BCC70748155":
    "https://s3.amazonaws.com/keybase_processed_uploads/0f1cdcc05e771e267cb1415327216c05_360_360.jpg",
  "355613DDE80039C8":
    "https://s3.amazonaws.com/keybase_processed_uploads/27f1664463f26fd64cb549eba3567405_360_360.jpg",
  "7ACD3320CCADD897":
    "https://s3.amazonaws.com/keybase_processed_uploads/bc255fca6c64687aa53d62500f9f3605_360_360.jpg",
  "4E0CE8E709527EE0":
    "https://s3.amazonaws.com/keybase_processed_uploads/f8e675a87c4ceaee210dd59a09332c05_360_360.jpg",
  C8992BB62C009B9F:
    "https://s3.amazonaws.com/keybase_processed_uploads/0ef4ba226a2717dca5f367e657e2d405_360_360.jpg",
  D0D9D1C2AEB79C5B:
    "https://s3.amazonaws.com/keybase_processed_uploads/c0d8c8d028e391b8ac5ea4c88a866b05_360_360.jpg",
  DFEAAB98E8D0975B:
    "https://s3.amazonaws.com/keybase_processed_uploads/00c1e1d42b5f6673011939ffbc753105_360_360.jpg",
  "74D3AF53635231D9":
    "https://s3.amazonaws.com/keybase_processed_uploads/3b4fcc88223b68911846a884ee63ab05_360_360.jpg",
  "6257A55EA42BA680":
    "https://s3.amazonaws.com/keybase_processed_uploads/42a43c92fd7896697eaf8157dad39505_360_360.jpg",
  E0A6A3980E464A66:
    "https://s3.amazonaws.com/keybase_processed_uploads/cb19889f449c505310929100874bb505_360_360.jpg",
  "70D77FD98E48B033":
    "https://s3.amazonaws.com/keybase_processed_uploads/e29c8d812b7515729b9a16e466295705_360_360.jpg",
  C7D6DBE2CB576363:
    "https://s3.amazonaws.com/keybase_processed_uploads/802332fc652f99a333844817aaddc105_360_360.jpg",
  EB3470949B3E89E2:
    "https://s3.amazonaws.com/keybase_processed_uploads/a084e1a96ecc3f24bd57dfec517a9205_360_360.jpg",
  "85130F5D06D9DC5E":
    "https://s3.amazonaws.com/keybase_processed_uploads/9a1eded7b114bb964a180406d515e805_360_360.jpg",
  A3431CED2751636A:
    "https://s3.amazonaws.com/keybase_processed_uploads/ec8e247e65ad9e156adf385a5106ea05_360_360.jpg",
  "3C91979AF36E303E":
    "https://s3.amazonaws.com/keybase_processed_uploads/68f2d676f48d880c67977fa15fda3905_360_360.jpg",
  "3912AE47B45446D7":
    "https://s3.amazonaws.com/keybase_processed_uploads/47efbd252439bbe957894b8c9a737205_360_360.jpg",
  "059BCF656623D0BE":
    "https://s3.amazonaws.com/keybase_processed_uploads/352ea94dd42598f2bd722b8aec5e2f05_360_360.jpg",
  "94F50BA69A2BEFEA":
    "https://s3.amazonaws.com/keybase_processed_uploads/dfbff60aaba51d8db0d1f31419a3d305_360_360.jpg",
  BA341405E1973689:
    "https://s3.amazonaws.com/keybase_processed_uploads/8103fbe299362603f6607f5b5db3c305_360_360.jpg",
  AB99C8D824487B05:
    "https://s3.amazonaws.com/keybase_processed_uploads/12b6eb10810ebcc4ee98443d8bf89d05_360_360.jpg",
  "7AAAA066B64C3034":
    "https://s3.amazonaws.com/keybase_processed_uploads/30d4ee1451e4206d3e654a81b04ba805_360_360.jpg",
  "4700D12228CC5EB5":
    "https://s3.amazonaws.com/keybase_processed_uploads/b91782b3a5e9c53d71e16b61d8be0405_360_360.jpg",
  "0EE0E1ADCAEC1633":
    "https://s3.amazonaws.com/keybase_processed_uploads/b5ef82839ef79ba26e8a4f58c0e81f05_360_360.jpg",
  "38172502B043D302":
    "https://s3.amazonaws.com/keybase_processed_uploads/dc696d875cbf0c5b745080101d914605_360_360.jpg",
  FF4B91B50B71CEDA:
    "https://s3.amazonaws.com/keybase_processed_uploads/77f05c9f4479b689156a691b2640f305_360_360.jpg",
  "26FA2B24F46A98EF":
    "https://s3.amazonaws.com/keybase_processed_uploads/101cd6ba9f2b4c46d56b4caaca764205_360_360.jpg",
  "3D7812E90AFB1548":
    "https://s3.amazonaws.com/keybase_processed_uploads/a1b0b98f51cbdc1ca319017576fb4c05_360_360.jpg",
  "8265DEAF50B61DF7":
    "https://s3.amazonaws.com/keybase_processed_uploads/b3273b5e67fbb587d207293e478aab05_360_360.jpg",
  EC771B7A05CDF1D4:
    "https://s3.amazonaws.com/keybase_processed_uploads/22d564bd8287141afbf59961df853205_360_360.jpg",
  "834d3ed959fe610fa2154c34ecdd85340e88a1ae":
    "https://s3.amazonaws.com/keybase_processed_uploads/472fe8ea959e6aa2443ac61c96004805_360_360.jpg",
  "716F93BF335CF0B0":
    "https://s3.amazonaws.com/keybase_processed_uploads/c7693c519da9ad61020eec267902cd05_360_360.jpg",
  EC3443CC6E038CFA:
    "https://s3.amazonaws.com/keybase_processed_uploads/5c7e3688032b14325ea89b42af319205_360_360.jpg",
  F8FCC108B0120E16:
    "https://s3.amazonaws.com/keybase_processed_uploads/d85f442f668abaac037203356ee6d905_360_360.jpg",
  "562B310A60D8A06D":
    "https://s3.amazonaws.com/keybase_processed_uploads/ce67dbce205b0b64379ac32636615605_360_360.jpg",
  "69A46F39FB01F4D4":
    "https://s3.amazonaws.com/keybase_processed_uploads/11b3dd78058506ef2e45cde098008d05_360_360.jpg",
  "2CB281A714F6133B":
    "https://s3.amazonaws.com/keybase_processed_uploads/53b008f12f37e3ffa0dec3676d375a05_360_360.jpg",
  BEAC09B6FE7F908B:
    "https://s3.amazonaws.com/keybase_processed_uploads/bec2c55ae55d417f928a323fd8815105_360_360.jpg",
  "3D6E2861B47F2F9F":
    "https://s3.amazonaws.com/keybase_processed_uploads/b10c191914fa49558addc492955c8a05_360_360.jpg",
  "6CE4A2AB30E12FB6":
    "https://s3.amazonaws.com/keybase_processed_uploads/40074803b431974d29cf2693562bba05_360_360.jpg",
  AD6C05DA12E42B70:
    "https://s3.amazonaws.com/keybase_processed_uploads/7757c06ab563563da07d5423d7675705_360_360.jpg",
  "48CE867E6AB5ED72":
    "https://s3.amazonaws.com/keybase_processed_uploads/a4252044b74200386ac1473bfca95c05_360_360.jpg",
  A15273DFFD11E62E:
    "https://s3.amazonaws.com/keybase_processed_uploads/6765b2fa9b5a3168efb48eeb04093605_360_360.jpg",
  EDEA30F3AD071CD2:
    "https://s3.amazonaws.com/keybase_processed_uploads/ed8fd11c2a19472bf4871d4dc66c8405_360_360.jpg",
  "654899A548A41038":
    "https://s3.amazonaws.com/keybase_processed_uploads/d006ff2692078b96b7d54ebfd6c84205_360_360.jpg",
  "3EB183235B56FFFD":
    "https://s3.amazonaws.com/keybase_processed_uploads/7f9829a56a441dc172bf30580e7c4d05_360_360.jpg",
  "2C12B61930DF3586":
    "https://s3.amazonaws.com/keybase_processed_uploads/c2fdefa03db4d6ef1872239955449e05_360_360.jpg",
  "0E480E2B83B23D80":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2F6ce44a0b3bbd2a99933ccb10a4a46305_360_360.jpg&w=128&q=75",
  "09A303A2C724C59":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper102ruvpv2srmunfffxavttxnhezln6fncrdjd27.png",
  "2B1788BD8D14A3AF":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2F1db7b1f4f2030c71264fb76507a09a05_360_360.jpg&w=128&q=75",
  "357F80896B3311B4":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2F627921d6ba4c1f941d0a12b015a2bc05_360_360.jpg&w=128&q=75",
  "84D5F4CF21FBF123":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2Fd2b22684de5f464da40742f84b737905_360_360.jpg&w=64&q=75",
  "3820B9E30B3BD99E":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2Ff0238d018c8256e54e8109406786e505_360_360.jpg&w=64&q=75",
  "9E31CFA37DA22B31":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1knn0dpjfukmq97yz09uqt80896gwg0rfv6jtvp.png",
  "9B2EB22C7DAC8684":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper183psjr4y05kwtpfew073q6hm84xdhp9tcn8ne7.png",
  "44771D06A00DD695":
    "https://wallet.keplr.app/_next/image?url=https%3A%2F%2Fs3.amazonaws.com%2Fkeybase_processed_uploads%2F173328839c66214bd12fc8100bd04105_360_360.jpg&w=64&q=75",
  B74BDDE339591690:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper15004ysvmqnqzkvt7x6s4cd53flmmvgfv6t4y9y.png",
  osmovaloper13eq5c99ym05jn02e78l8cac2fagzgdhh4294zk:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper13eq5c99ym05jn02e78l8cac2fagzgdhh4294zk.png",
  osmovaloper1vcpryrtatk6c8z6tcyx9w45jsw56rvkqapgq6p:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1vcpryrtatk6c8z6tcyx9w45jsw56rvkqapgq6p.png",
  osmovaloper1hk64xxqufcdyh627s29mruep8yfmxpuuclacer: valFallback,
  osmovaloper1h3rgv70cp2uapm5066l38u468udxkx3ecdpv04: valFallback,
  osmovaloper1prmsfrgvla0u8x3kwc8k0mcqqve3h8y73d37nm: valFallback,
  osmovaloper1eh5mwu044gd5ntkkc2xgfg8247mgc56f4dlt9h: valFallback,
  osmovaloper1epp52vecttkkvs3s84c9m8s2v2jrf7gtpx6pq0: valFallback,
  osmovaloper1lfn5ywlunadd3fl8lcem05ew7vezns2kx06pf0:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1lfn5ywlunadd3fl8lcem05ew7vezns2kx06pf0.png",
  osmovaloper1tv9wnreg9z5qlxyte8526n7p3tjasndede2kj9:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1tv9wnreg9z5qlxyte8526n7p3tjasndede2kj9.png",
  osmovaloper10kn8nhlchp3qxdvsvte4zepfkpvlfarp8nk8pf:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper10kn8nhlchp3qxdvsvte4zepfkpvlfarp8nk8pf.png",
  osmovaloper1fdratlcgy56etxd07n03vcfm20m4mff8qzn2p3: valFallback,
  osmovaloper1z2q6gse6fafvdju6zvgx7r7sj6zjtg75xu6cg0:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1z2q6gse6fafvdju6zvgx7r7sj6zjtg75xu6cg0.png",
  osmovaloper1cm83xqns9sqhv9a8vd6fggt6mvuu8mmzszknae:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1cm83xqns9sqhv9a8vd6fggt6mvuu8mmzszknae.png",
  osmovaloper1kls9ca0h980sqfmt0497jj8kad3lcws6g08mld:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1kls9ca0h980sqfmt0497jj8kad3lcws6g08mld.png",
  osmovaloper1j7euyj85fv2jugejrktj540emh9353ltnz0lvc:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1j7euyj85fv2jugejrktj540emh9353ltnz0lvc.png",
  osmovaloper1y6y38g5pxcu3ca2v8merufs9eagnqd9u2yf5ng: valFallback,
  osmovaloper1gjtvly9lel6zskvwtvlg5vhwpu9c9wawfgfyrq:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1gjtvly9lel6zskvwtvlg5vhwpu9c9wawfgfyrq.png",
  osmovaloper14lzvt4gdwh2q4ymyjqma0p4j4aykpn92l4warr: valFallback,
  osmovaloper18wkkhsrsw7gwl3z9gn6e25ag3sn0crzp2tp9t0: valFallback,
  osmovaloper12t3xdsjlkczucc3dznpvrll7n9lyt3mulhp7ue: valFallback,
  "9633C081790FAB0A": valFallback,
  F74595D6D5D568A2:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1feh2keupglep6mvxf5c96eulh3puujjryj2h8v.png",
  AB3DA9B8891D4F85:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper10w06wvmdd4xn7hr76gzrv570f7q5uweem5jcd7.png",
  "7C7B7DADFECA3F7F": valFallback,
  osmovaloper14n8pf9uxhuyxqnqryvjdr8g68na98wn5amq3e5:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper14n8pf9uxhuyxqnqryvjdr8g68na98wn5amq3e5.png",
  osmovaloper13t30u88ehaq3dlp5uzkxmn9u98nrskjyrutvvs: valFallback,
  osmovaloper1h2c47vd943scjlfum6yc5frvu2l279lwjep5d6:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1h2c47vd943scjlfum6yc5frvu2l279lwjep5d6.png",
  osmovaloper1glmy88g0uf6vmw29pyxu3yq0pxpjqtzqr5e57n:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1glmy88g0uf6vmw29pyxu3yq0pxpjqtzqr5e57n.png",
  osmovaloper1u6jr0pztvsjpvx77rfzmtw49xwzu9kas05lk04:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1u6jr0pztvsjpvx77rfzmtw49xwzu9kas05lk04.png",
  osmovaloper1r2u5q6t6w0wssrk6l66n3t2q3dw2uqny4gj2e3:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1r2u5q6t6w0wssrk6l66n3t2q3dw2uqny4gj2e3.png",
  osmovaloper1y0us8xvsvfvqkk9c6nt5cfyu5au5tww24nrlnx:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1y0us8xvsvfvqkk9c6nt5cfyu5au5tww24nrlnx.png",
  osmovaloper1q4mlwtw3ncve72na65xacck557wcknc8j0lmke:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1q4mlwtw3ncve72na65xacck557wcknc8j0lmke.png",
  osmovaloper14amduhjazqhwtkhm6kutdcy4ux5zazf5k803tq:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper14amduhjazqhwtkhm6kutdcy4ux5zazf5k803tq.png",
  osmovaloper14fpkylq7y7eenakv0h56yu8jh922p9uvyeugty: valFallback,
  osmovaloper1md9f5524vtmrn64lyv2pdfn7cnkjkklf44vtjz:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1md9f5524vtmrn64lyv2pdfn7cnkjkklf44vtjz.png",
  osmovaloper13tk45jkxgf7w0nxquup3suwaz2tx483xe832ge:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper13tk45jkxgf7w0nxquup3suwaz2tx483xe832ge.png",
  osmovaloper1wgmdcxzp49vjgrqusgcagq6qefk4mtjv5c0k7q: valFallback,
  osmovaloper1nna7k5lywn99cd63elcfqm6p8c5c4qcu6ex2gp: valFallback,
  osmovaloper1tf7wjthwt9mgywqvjdkj974rp5awzzm43ddhlp: valFallback,
  osmovaloper1kgddca7qj96z0qcxr2c45z73cfl0c75pf37k8l:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1kgddca7qj96z0qcxr2c45z73cfl0c75pf37k8l.png",
  osmovaloper1xk23a255qm4kn6gdezr6jm7zmupn23t3pqjjn6:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1xk23a255qm4kn6gdezr6jm7zmupn23t3pqjjn6.png",
  osmovaloper17r0cp9zrtkvzlz7a2r09gmdxwqfyje98gfer6u: valFallback,
  "3CE640138108BAAE":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper14n8pf9uxhuyxqnqryvjdr8g68na98wn5amq3e5.png",
  "158DA6C7FCFB7BD23988D9C0D0D8B80F1C5C70B5":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1r2u5q6t6w0wssrk6l66n3t2q3dw2uqny4gj2e3.png",
  "1BEC0B582AEDB0EC": valFallback,
  C99B3A6836BF0CB8:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1md9f5524vtmrn64lyv2pdfn7cnkjkklf44vtjz.png",
  "97EE2A4FADFB1524":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper13tk45jkxgf7w0nxquup3suwaz2tx483xe832ge.png",
  EF5AC70C00BECEDC: valFallback,
  E072BD129BFECFB1: valFallback,
  A3870C87F2634CB5:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1tf7wjthwt9mgywqvjdkj974rp5awzzm43ddhlp.png",
  "6D5F63F1DDCF0404":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1xk23a255qm4kn6gdezr6jm7zmupn23t3pqjjn6.png",
  "496198D003A5A717": valFallback,
  "5307DE5B18FEA0AA":
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper1u2wsa88eh738mk35a3cncgu7lc6cqf74jdl50x.png",
  A91AFDD35644AC49: valFallback,
  A5C785745A5F9D3E: valFallback,
  CE2C5C02D96391AA:
    "https://raw.githubusercontent.com/cosmostation/chainlist/master/chain/osmosis/moniker/osmovaloper135p8cjwa3hnnnksjxchda0a6339z9zm7cudyxn.png",
  FED16ABFC532209B: valFallback,
  DE50D46AAC2A243C: valFallback,
  AC3C2AB4800FEC24: valFallback,
  "93EEE9A0E6827ECA": valFallback,
  osmovaloper1f7jtv9sd3g9jay64zrydfqh7l3eqdzgs289m8u: valFallback,
  "6C1013A10D92CB91": valFallback,
  "2AB1C2C6BEADE9F0": valFallback,
  D09E1D871392C163: valFallback,
};
